form{
    border:1px solid #666;
    padding:20px;
    width:100%;
    max-width:600px;
    margin:20px auto;
}

label{
    display:block;
    text-align: left;
    text-transform: uppercase;
    margin-bottom:10px;
    font-size:12px;
    letter-spacing: 2px;
}

input[type="text"],
input[type="email"],
textarea{
    padding:10px;
    width:100%;
    margin-bottom:20px;
}

textarea{
    height:120px;
}

form button{
    text-transform: uppercase;
    margin-bottom:10px;
    font-size:12px;
    letter-spacing: 2px;
    color:#666;
    border:1px solid #666;
    padding:10px 30px;
}

form button:hover{
   background-color: #666; 
   color:#fff;

}