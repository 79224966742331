.homeContainer {
  border-top: 2px solid #444;
  border-bottom: 2px solid #444;
  position: absolute;
  top: 79px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}

.homeContainer svg {
  width: 100%;
}

/* Define the animation */
@keyframes move {
  0% {
    transform: translateX(-500px);
  }
  100% {
    transform: translateX(2000px);
  }
}

@keyframes drift {
  0% {
    transform: translateX(-2000px);
  }
  100% {
    transform: translateX(2000px);
  }
}


.boundingRect {
  fill: none;
  stroke:none;
}

.groundLineA {
  fill: none;
  stroke: #808080;
  stroke-width: 6px;
}

.groundLineB {
  fill: none;
  stroke: #585858;
  stroke-width: 6px;
}

.groundLineC {
  fill: none;
  stroke: #3d3d3d;
  stroke-width: 6px;
}

.groundLineD {
  fill: none;
  stroke: #323232;
  stroke-width: 6px;
}

.groundLineE {
  fill: none;
  stroke: #222222;
  stroke-width: 6px;
}

.groundLineF {
  fill: none;
  stroke: #111111;
  stroke-width: 6px;
}

.fillA {
  fill: #808080;
}

.fillB {
  fill: #585858;
}

.fillC {
  fill: #444444;
}





#mountains {
  stroke: #2c2c2c;
  stroke-width: 5.7px;
  fill: #000000;
}

.cloud01 {
  stroke: #808080;
  stroke-width: 2px;
}

.cloud02 {
  fill: none;
  stroke: #454545;
  stroke-width: 2px;
}

.cloud03 {
  fill: none;
  stroke: #616161;
  stroke-width: 2px;
}

.cloud04 {
  fill: none;
  stroke: #343434;
  stroke-width: 2px;
}


#bigCloud01 {
  animation: drift 80s linear infinite;
  animation-delay: -25s;
}

#bigCloud02 {
  animation: drift 120s linear infinite;
}

#bigCloud03 {
  animation: drift 120s linear infinite;
  animation-delay: -60s;
}

.drift01{
  fill: #ffffff22;
  stroke: none;
  animation: move 50s linear infinite;
  animation-delay: -25s;
}

.drift02{
  fill: #ffffff22;
  stroke: none;
  animation: move 60s linear infinite;
  animation-delay: 0s;
  animation-delay: -10s;
}


.drift03{
  fill: #ffffff11;
  animation: drift 90s linear infinite;
  animation-delay: 0s;
}


.drift04{
  fill: #ffffff11;
  animation: drift 90s linear infinite;
  animation-delay: -45s;

}

.drift05{
  fill: #ffffff11;
  stroke: none;
  animation: move 70s linear infinite;
  animation-delay: 0s;
}

.yellowLine{
  fill: #ffffff22;
  stroke: yellow;
}

#bigCloud04 {
  display:none;
}