html,
body {
  width: 100%;
  height: 100vh;
  background-color: #000;
}

body {
  margin: 0px;
}

.container {
  color: #ccc;
  font-family: "Urbanist", sans-serif;
  text-align: center;
  /*border:1px solid #444;*/
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  overflow: hidden;
}

.menuBox {
  position: absolute;
  width: 170px;
  left: 60px;
  margin-left: -85px;
  transition: all 0.5s ease; /* Smooth transition for the left property */
}

.menuBox .panel{
  transition: height 0.5s ease; 
}

.home .menuBox {
  left: 50%;
}

.menuBox img {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
}

.menuBox .panel {
  padding: 10px;
  padding-top: 50px;
  border: 2px solid #666;
  border-radius: 8px;
  margin-top: 60px;
  background-color: #00000099;
  opacity:0;
  height:0px;
  overflow:hidden;
}

.home .menuBox .panel {
  opacity:1;
  height:234px;
}

button {
  background-color: transparent;
  border: none;
}

button:hover h1,
button:hover h2 {
  color: #fff;
}

h1 {
  text-transform: uppercase;
  color: #666;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

h2 {
  text-transform: uppercase;
  color: #666;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 20px;
  font-size: 12px;
}

h3,
h4 {
  color: #ccc;
  letter-spacing: 2px;
  margin-bottom: 10px;
  font-size: 12px;
  white-space: nowrap;
}

h3 {
  font-style: italic;
}

h4 {
  font-size: 10px;
}

.menuBox svg {
  margin-bottom: 5px;
}

.scrollBox {
  border-top: 1px solid #444;
  position: absolute;
  top: 40px;
  left: 10px;
  right: 10px;
  bottom: 40px;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: thin;
  scrollbar-color: #aaa #444;
  min-height: 370px;
}

.scrollBoxGroup {
  height: 100%;
  width: 500%;
  display: flex;
  padding-left: 200px;


}

.scrollBoxGroup section {
  flex: 1;
  margin: 10px;
  display: inline-block;

}

.scrollBoxGroup section.capSection {
  flex: 0.5;
  border: none;
  padding: 10px;
  margin: 10px;
  margin-right: 30px;
  text-align: left;
}

.scrollBoxGroup section img {
  height: 100%;
}





.landscapes .scrollBoxGroup section  {
  padding-top:40px;
}

.contactContainer{
border-top: 1px solid #444;
border-bottom: 1px solid #444;
position: absolute;
top: 40px;
left: 10px;
right: 10px;
bottom: 40px;
overflow: hidden;
min-height: 370px;
}


footer {
  position: absolute;
  bottom: 5px;
  left: 0px;
  width: 100%;
  font-size: 10px;
  color: #666;
  letter-spacing: 1px;
}

.uiux .scrollBoxGroup {
  width: 550%;
}

.uiux section img {
  height: 70%;
  max-width: 100%;
}

.uiux section {
  text-align: left;
  min-width: 700px;
  margin-right: 2%;
  font-size: 100%;
  line-height: 18pt;
  padding-top:10px;
}

.uiux section h2 {
  text-align: right;
}

.uiux section h3 {
  text-align: right;
  font-style: normal;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
}

.uiux section.intro h3 {
  font-size: 30px;
  margin-bottom: 20px;
}

.uiux section p {
  margin-bottom: 10px;
}

.columnContainer {
  display: flex;
  margin-bottom: 30px;
}

.column {
  flex: 1;
}

.designCode .column:last-child,
section.intro .column {
  background-color: #333;
  padding: 15px;
  border-radius: 4px;
}

section.intro .column:first-child {
  margin-right: 10px;
}

section.intro .column:last-child {
  margin-left: 10px;
}

em {
  font-weight: bold;
  font-style: italic;
  margin-bottom: 10px;
  display: block;
}

.designCode .column:first-child {
flex:2;
}

.designCode .column:last-child {
  background-color: #333;
  padding: 15px;
  border-radius: 4px;
  margin-left:20px;
}

.designCode .column:last-child h3 {
  font-size: 30px;
  margin-bottom: 20px;
}

.designCode .column:last-child ul li{
  border-bottom:1px solid #666;
  padding-top:5px;
  padding-bottom:5px;
}

.designCode .column:first-child ul{
  list-style-type: disc; 
  margin-left:20px;
}

.designCode .column:first-child ul li{
  padding-top:5px;
  padding-bottom:5px;
}


.landscapes .imgScroller section img {
  height: 90%;
}

.landscapes section,
.illustration section{
  text-align: left;
}


.landscapes section div.imgInfo,
.illustration section div.imgInfo{
padding-top:5px;
  text-align: left;
  display:block;
}



.landscapes section div.imgInfo h3,
.landscapes section div.imgInfo h4,
.illustration section div.imgInfo h3,
.illustration section div.imgInfo h4{
  color:#999;
}

.contactThanks{
  margin-top:100px;
}

.illustration .imgScroller section img {
  height: 90%;
}

.imgScroller {
  height: 100%;
  padding-left:120px;
  white-space: nowrap; 
  padding-top:20px;
}

.landscapes .imgScroller section,
.illustration .imgScroller section{
  display:inline-block;
  height:100%;
  margin-right:200px;
}

